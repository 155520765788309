import { calculatePercentage, containWord, convertSpeed, formatTime } from "./commonUtils";
import { getPositionGroupsBySport } from "./pdfUtils";


const ZONE_NAMES = [
    "Warm-Up Zone",
    "Fitness Zone",
    "Performance Zone",
    "High Intensity Zone"
];


// Aggregates zone data from multiple dates by summing up relevant fields 
// and calculating averages for each zone.
const aggregateZoneData = (zoneValues) => {
    const aggregated = [];

    zoneValues.forEach((dateZones, dateIndex) => {
        dateZones.forEach((zone, index) => {
            if (!aggregated[index]) {
                aggregated[index] = {
                    edges: zone.edges,
                    seconds_played: 0,
                    distance: 0,
                    mean_value: 0,
                    count: 0,
                };
            }

            aggregated[index].seconds_played += zone.seconds_played ?? 0;
            aggregated[index].distance += zone.distance ?? 0;
            aggregated[index].mean_value += zone.mean_value ?? 0;
            aggregated[index].count += 1;
        });
    });

    // Calculate averages for seconds_played, distance, and mean_value
    aggregated.forEach((zone, index) => {
        if (zone.count > 0) {
            zone.seconds_played = zone.seconds_played / zone.count;
            zone.distance = zone.distance / zone.count;
            zone.mean_value = zone.mean_value / zone.count;
        }

        delete zone.count;
    });

    return aggregated;
};


// Transforms a single field based on provided conditions for displaying or converting values.
const transformField = (value, isTeamKab, isSpeed = false, isSeconds = false) => {
    const transformedValue = isSpeed && isTeamKab
        ? convertSpeed(value ?? 0)
        : value ?? 0;

    if (transformedValue === 0) {
        return {
            display: "-",
            value: transformedValue,
        };
    }

    const displayValue = isSpeed
        ? transformedValue.toFixed(2)
        : isSeconds
            ? formatTime(transformedValue)
            : transformedValue;

    return {
        display: displayValue,
        value: transformedValue,
    };
};


// Transforms raw zone data into a more structured format with additional fields,
// such as percentages of duration and distance for each zone.
const transformZones = (zones, zoneNames, totalSeconds, totalDistance, isTeamKab) => {
    if (!Array.isArray(zones)) {
        return { zonesEmpty: true }; // Return an object with empty zone param if zones are invalid
    }

    if (!zones.length) {
        return { zonesEmpty: true }; // Return an object with empty zone param if there are no zones 
    }

    return zones.reduce((acc, zone, index) => {
        if (!zone || typeof zone !== "object") {
            return acc; // Skip invalid zones
        }

        const name = zoneNames[index] || `zone${index + 1}`;
        const trZones = { name };

        Object.keys(zone).forEach((key) => {
            const roundedValue = Math.round(zone[key]);
            switch (key) {
                case "edges":
                    trZones[key] = zone[key] || []; // Default to an empty array
                    break;

                case "seconds_played":
                    const durationPercentage = calculatePercentage(roundedValue || 0, totalSeconds);
                    trZones["duration"] = transformField(roundedValue || 0, isTeamKab, false, true);
                    trZones["duration_percentage"] = {
                        display: `${durationPercentage}%`,
                        value: parseFloat(durationPercentage),
                    };
                    break;

                case "distance":
                    const distancePercentage = calculatePercentage(roundedValue || 0, totalDistance);
                    trZones[key] = transformField(roundedValue || 0, isTeamKab);
                    trZones["distance_percentage"] = {
                        display: `${distancePercentage}%`,
                        value: parseFloat(distancePercentage),
                    };
                    break;

                case "mean_value":
                    trZones[key] = transformField(zone[key] || 0), isTeamKab;
                    break;

                default:
                    trZones[key] = transformField(zone[key] || 0, isTeamKab);
                    break;
            }
        });

        acc[`zone${index + 1}`] = trZones;
        return acc;
    }, {});
};


// Calculates the average duration and distance for each zone based on raw data from multiple players.
const calculateZoneAverage = (rawData) => {
    const zonesAvg = Array.from({ length: 4 }, (_, index) => {
        const allDurations = rawData
            .flatMap((player) => player.zone_values.map((zones) => zones[index]?.seconds_played || 0))
            .filter((value) => value > 0);

        const allDistances = rawData
            .flatMap((player) => player.zone_values.map((zones) => zones[index]?.distance || 0))
            .filter((value) => value > 0);

        const averageDuration = allDurations.length
            ? allDurations.reduce((sum, val) => sum + val, 0) / allDurations.length
            : 0;

        const averageDistance = allDistances.length
            ? allDistances.reduce((sum, val) => sum + val, 0) / allDistances.length
            : 0;

        return {
            averageDuration,
            averageDistance,
        };
    });

    const teamDurAvg = {};
    const teamDistAvg = {};

    ZONE_NAMES.forEach((zoneName, index) => {
        teamDurAvg[zoneName] = formatTime(zonesAvg[index].averageDuration);
        teamDistAvg[zoneName] = parseFloat(zonesAvg[index].averageDistance.toFixed(2));
    });

    return [teamDurAvg, teamDistAvg];
};


const addTeamZoneValues = (teamDetails, posArea, zoneValues) => {
    const zoneDetails = {};
    zoneDetails[posArea] = {};
    zoneDetails["All"] = {};

    zoneValues.forEach((zone, index) => {
        const distanceKey = ZONE_NAMES[index] + " " + "Distance";
        const durationKey = ZONE_NAMES[index] + " " + "Duration";
        zoneDetails[posArea][distanceKey] =
            teamDetails[posArea][distanceKey] + Math.round(zone["distance"]);

        zoneDetails[posArea][durationKey] =
            teamDetails[posArea][durationKey] + Math.round(zone["seconds_played"]);

        zoneDetails["All"][distanceKey] =
            teamDetails["All"][distanceKey] + Math.round(zone["distance"]);

        zoneDetails["All"][durationKey] =
            teamDetails["All"][durationKey] + Math.round(zone["seconds_played"]);
    })

    return { ...zoneDetails };
}

const addTeamMetricValues = (item, teamDetails, posArea, isTeamKab) => {
    const excluded_keys = [
        "BIRTHDATE",
        "FIRST_NAME",
        "LAST_NAME",
        "PLAYER_ID",
        "POSITION",
        "WEIGHT",
        "image",
        "no",
        "zone_values",
        "height",
    ];

    const teamMetricDetails = {};
    teamMetricDetails[posArea] = {};
    teamMetricDetails["All"] = {};

    Object.keys(item).forEach((key) => {
        if (!excluded_keys.includes(key)) {
            const value = isTeamKab && containWord(key, "speed") ?
                convertSpeed(item[key]) :
                item[key];

            teamMetricDetails[posArea][key] = teamDetails[posArea][key] + value;
            teamMetricDetails["All"][key] = teamDetails["All"][key] + value;
        }
    })

    return { ...teamMetricDetails };
}

const addItemInTeamDetails = (
    item,
    zones,
    teamDetails,
    positionGroup,
    totalPlayers,
    isTeamKab,
) => {
    const result = { ...teamDetails };

    if (!Object.keys(item).length) return result;

    let positionArea;
    Object.keys(positionGroup).forEach((posGroup) => {
        if (positionGroup[posGroup].includes(item.POSITION)) {
            positionArea = posGroup;
            return;
        }
    })

    const convertZonesToDict = (zone_values) => {
        const result = {};
        if (!zone_values) return result;

        for (let i = 0; i < zone_values.length; i++) {
            result[`${ZONE_NAMES[i]} Distance`] = zone_values[i].distance;
            result[`${ZONE_NAMES[i]} Duration`] = zone_values[i].seconds_played;
        }
        return result;
    }

    const zItem = convertZonesToDict(item.zone_values[0]);

    Object.keys(totalPlayers[positionArea]).forEach((key) => {
        if (containWord(key, "Zone")) {
            if (zItem[key] !== 0 && zItem[key] !== null && zItem[key] !== undefined) {
                totalPlayers[positionArea][key] += 1;
                totalPlayers["All"][key] += 1;
            }
        } else if (item[key] !== 0 && item[key] !== null && item[key] !== undefined) {
            totalPlayers[positionArea][key] += 1;
            totalPlayers["All"][key] += 1;
        }
    });

    const teamMetricDetails = addTeamMetricValues(item, teamDetails, positionArea, isTeamKab);
    const teamZoneDetails = addTeamZoneValues(teamDetails, positionArea, zones);

    // Combine dictionaries, merging sub-objects
    const newTeamDetails = Object.keys(teamDetails).reduce((acc, key) => {
        acc[key] = { ...teamDetails[key], ...teamMetricDetails[key], ...teamZoneDetails[key] }; // Merge inner objects
        return acc;
    }, {});

    return { ...newTeamDetails };
}

const calcTeamAvgZoneHelper = (teamDetails, totalDur, totalDist) => {
    const tDetails = {}

    Object.keys(teamDetails).forEach(posArea => {
        tDetails[posArea] = {};
        Object.keys(teamDetails[posArea]).forEach(key => {
            tDetails[posArea][key] = teamDetails[posArea][key];

            if (!containWord(key, "Zone")) return;

            const newKey = key + " " + "(%)";
            if (isNaN(teamDetails[posArea][key])) {
                tDetails[posArea][newKey] = 0;

            } else if (containWord(key, "Duration")) {
                tDetails[posArea][newKey] =
                    parseFloat((100 * teamDetails[posArea][key] / totalDur[posArea]).toFixed(2));

            } else {
                tDetails[posArea][newKey] =
                    parseFloat((100 * teamDetails[posArea][key] / totalDist[posArea]).toFixed(2));
            }
        })
    });

    return { ...tDetails };
}

const calcTotalInZonesAndTeamAvgHelper = (
    positionGroup,
    teamDetails,
    totalPlayers,
    // totalDur,
    // totalDist
) => {
    const newTeamDetails = {};
    const totalDur = {};
    const totalDist = {};

    let posGroups = Object.keys(positionGroup);
    posGroups.push("All");

    posGroups.forEach(posArea => {
        // Initiate dictionaries for each position area
        newTeamDetails[posArea] = {};
        totalDur[posArea] = 0;
        totalDist[posArea] = 0;

        Object.keys(teamDetails[posArea]).forEach(key => {
            newTeamDetails[posArea][key] =
                parseFloat((teamDetails[posArea][key] / totalPlayers[posArea][key]).toFixed(2));

            if (!containWord(key, "Zone") ||
                isNaN(newTeamDetails[posArea][key])
            )
                return;

            if (containWord(key, "Duration")) {
                totalDur[posArea] += newTeamDetails[posArea][key];
            } else {
                totalDist[posArea] += newTeamDetails[posArea][key];
            }
        })
    });

    return [newTeamDetails, totalDur, totalDist];
}
// Calculate the averages for each position area and key
const calcTeamAvg = (teamDetails, totalPlayers, positionGroup) => {
    const [newTeamDetails, totalDur, totalDist] = calcTotalInZonesAndTeamAvgHelper(positionGroup, teamDetails, totalPlayers);
    return calcTeamAvgZoneHelper(newTeamDetails, totalDur, totalDist);
}


const initTeamAvg = (data, positionGroup, isBasketball) => {
    let result = {};

    let totalPlayers = {};
    const keysToExclude = [
        "BIRTHDATE",
        "FIRST_NAME",
        "LAST_NAME",
        "PLAYER_ID",
        "POSITION",
        "WEIGHT",
        "image",
        "no",
        "zone_values",
        "height",
    ];

    if (!data.length) return [result, totalPlayers];

    // Initialize the resultHelper with keys and zero values
    let resultHelper = {
        "Warm-Up Zone Distance": 0,
        "Warm-Up Zone Duration": 0,
        "Fitness Zone Distance": 0,
        "Fitness Zone Duration": 0,
        "Performance Zone Distance": 0,
        "Performance Zone Duration": 0,
        ...(!isBasketball ? {
            "High Intensity Zone Distance": 0,
            "High Intensity Zone Duration": 0
        } : {})
    };

    Object.keys(data[0]).forEach(key => {
        if (!keysToExclude.includes(key)) {
            resultHelper[key] = 0;
        }
    });

    Object.keys(positionGroup).forEach((key) => {
        result[key] = { ...resultHelper };
        totalPlayers[key] = { ...resultHelper };
    });

    result["All"] = { ...resultHelper };
    totalPlayers["All"] = { ...resultHelper };

    return [result, totalPlayers];
}


const FETCH_VALUES_TO_VAR_CONVERTER = {
    PLAYER_ID: "playerId",
    image: "avatar",
    // `${FIRST_NAME} ${LAST_NAME}`: "fullname",
    FIRST_NAME: "firstname",
    LAST_NAME: "lastname",
    POSITION: "position",
    active_duration_value: "activeTime",
    active_distance_travelled_value: "activeDistance",
    engagement_duration_value: "engagementTime",
    distance_travelled_value: "totalDistance",
    number_of_sprints_value: "totalSprints",
    sprints_distance_travelled_value: "sprintsDistance",
    avg_running_speed: "runningSpeed",
    max_speed_value: "maxSpeed",
    max_total_acceleration_value: "maxAcc",
    number_of_steps_value: "steps",
    max_acceleration_x_value: "maxAccX",
    max_acceleration_y_value: "maxAccY",
    max_acceleration_z_value: "maxAccZ",
    acceleration_count_value: "accel",
    deceleration_count_value: "decel",
};

const FETCH_VALUES_TO_VAR_DECONVERTER = {
    playerId: "PLAYER_ID",
    avatar: "image",
    // `${FIRST_NAME} ${LAST_NAME}`: "fullname"","
    firstname: "FIRST_NAME",
    lastname: "LAST_NAME",
    position: "POSITION",
    activeTime: "active_duration_value",
    activeDistance: "active_distance_travelled_value",
    engagementTime: "engagement_duration_value",
    totalDistance: "distance_travelled_value",
    totalSprints: "number_of_sprints_value",
    sprintsDistance: "sprints_distance_travelled_value",
    runningSpeed: "avg_running_speed",
    maxSpeed: "max_speed_value",
    maxAcc: "max_total_acceleration_value",
    steps: "number_of_steps_value",
    maxAccX: "max_acceleration_x_value",
    maxAccY: "max_acceleration_y_value",
    maxAccZ: "max_acceleration_z_value",
    accel: "acceleration_count_value",
    decel: "deceleration_count_value",
};

const createPlObjHelper = (
    item,
    isTeamKab,
    isBasketball,
    aggregatedZones,
    teamDistanceAverage,
    teamDurationAverage,
    totalDistance,
    totalSeconds,
) => {
    if (!item) {
        return {};
    }

    return {
        playerId: item.PLAYER_ID,
        avatar: item.image,
        fullname: `${item.FIRST_NAME} ${item.LAST_NAME}`,
        firstname: item.FIRST_NAME,
        lastname: item.LAST_NAME,
        position: item.POSITION,
        activeTime: transformField(item.active_duration_value, isTeamKab),
        activeDistance: transformField(item.active_distance_travelled_value, isTeamKab),
        engagementTime: transformField(item.engagement_duration_value, isTeamKab),
        totalDistance: transformField(item.distance_travelled_value, isTeamKab),
        ...(!isBasketball ? {
            totalSprints: transformField(item.number_of_sprints_value),
            sprintsDistance: transformField(item.sprints_distance_travelled_value)
        } : {}),
        runningSpeed: transformField(item.avg_running_speed, isTeamKab, true),
        maxSpeed: transformField(item.max_speed_value, isTeamKab, true),
        maxAcc: transformField(item.max_total_acceleration_value, isTeamKab),
        ...(!isTeamKab ? { steps: transformField(item.number_of_steps_value, isTeamKab) } : {}),
        maxAccX: transformField(item.max_acceleration_x_value, isTeamKab),
        maxAccY: transformField(item.max_acceleration_y_value, isTeamKab),
        maxAccZ: transformField(item.max_acceleration_z_value, isTeamKab),
        ...transformZones(
            aggregatedZones,
            ZONE_NAMES,
            totalSeconds,
            totalDistance,
            isTeamKab,
        ),
        accel: transformField(item.acceleration_count_value, isTeamKab),
        decel: transformField(item.deceleration_count_value, isTeamKab),
        teamDistanceAverage: teamDistanceAverage,
        teamDurationAverage: teamDurationAverage
    };
}

// Transforms the raw player data into a structured and enriched format with 
// calculated fields, aggregated data, and zone transformations.
const transformData = (rawData, isTeamKab, sport) => {
    if (!rawData || !rawData.length) {
        return [];
    }

    const isBasketball = sport === "Basketball";
    const positionGroup = getPositionGroupsBySport(sport);

    // const teamDetails = computeTeamAvg(rawData);
    const [teamDurationAverage, teamDistanceAverage] = calculateZoneAverage(rawData);
    let [teamDetails, totalPlayers] = initTeamAvg(rawData, positionGroup.groups, isBasketball);

    const transformedData = rawData.map((item) => {
        const aggregatedZones = aggregateZoneData(item.zone_values);

        const totalSeconds = aggregatedZones.reduce(
            (sum, zone) => sum + (zone.seconds_played ?? 0),
            0
        );

        const totalDistance = aggregatedZones.reduce(
            (sum, zone) => sum + (zone.distance ?? 0),
            0
        );

        teamDetails = addItemInTeamDetails(
            item,
            aggregatedZones,
            teamDetails,
            positionGroup.groups,
            totalPlayers,
            isTeamKab
        );

        return createPlObjHelper(
            item,
            isTeamKab,
            isBasketball,
            aggregatedZones,
            teamDistanceAverage,
            teamDurationAverage,
            totalDistance,
            totalSeconds,
        );
    });

    const teamAvgDetails = calcTeamAvg(teamDetails, totalPlayers, positionGroup.groups);
    return [transformedData, { ...teamAvgDetails }];
};


export {
    transformData,
    ZONE_NAMES,
    FETCH_VALUES_TO_VAR_CONVERTER,
    FETCH_VALUES_TO_VAR_DECONVERTER
};
