import { containWord, formatTime, splitString } from "./commonUtils";
import { FETCH_VALUES_TO_VAR_DECONVERTER } from "./metricsUtils";

const FOOTBALL_POSITIONS = {
    "Attacker": [
        "Centre-Forward",
        "Center Forward",
        "Centrer Forward",
        "Winger",
        "Left Winger",
        "Left WInger",
        "Central Forward",
        "Right Winger",
        "Striker",
    ],
    "Midfielder": [
        "Defensive Midfielder",
        "Central Midfielder",
        "Attacking Midfielder",
        "Defensive Midfield",
        "Central Midfield",
        "Midfielder",
        "Attacking Midfield",
    ],
    "Defender": [
        "Left Back",
        "Left-Back",
        "Right Back",
        "Right-Back",
        "Center Back",
        "Central Back",
        "Centre-Back",
        "C.Defender",
        "Central Defender",
        "Defender",
        "Goalkeeper",
    ],
};

const BASKETBALL_POSITIONS = {
  Guard: ["Guard"],
  Forward: ["Forward"],
  Center: ["Center"],
};

const EXCLUDED_KEYS = [
    "avatar",
    "firstname",
    "lastname",
    "fullname",
    "position",
    "playerId",
    "teamDistanceAverage",
    "teamDurationAverage",
    "maxAccX",
    "maxAccY",
    "maxAccZ",
    "zone1",
    "zone2",
    "zone3",
    "zone4",
    "zonesEmpty",
];

const EXCLUDED_ZONE_COLUMNS = [
    "name",
    "mean_value",
    "edges",
];

const ZONE_CONVERTER = {
    "zone1": "Warm-Up Zone",
    "zone2": "Fitness Zone",
    "zone3": "Performance Zone",
    "zone4": "High Intensity Zone",
    "Warm-Up Zone": "zone1",
    "Fitness Zone": "zone2",
    "Performance Zone": "zone3",
    "High Intensity Zone": "zone4",
};

const COLUMN_NAME_CONVERTER = {
    "Distance": "distance",
    "Distance (%)": "distance_percentage",
    "Duration": "duration",
    "Duration (%)": "duration_percentage",
};

const PDF_NAME_VERSION_CONVERTER = {
    activeDistance: "Act Dist (m)",
    activeTime: "Act Time (min)",
    engagementTime: "Tot Time (min)",
    totalDistance: "Tot Dist (m)",
    totalSprints: "Sprint (n)",
    sprintsDistance: "Sprints Dist (m)",
    runningSpeed: "Run Spd (km/h)",
    steps: "Steps (n)",
    maxSpeed: "Max Spd (km/h)",
    maxAcc: "Max Acc (m/s²)",
    accel: "Accel (n)",
    decel: "Decel (n)",
    "Warm-Up Zone Distance": "W-U Z Dist (m)",
    "Warm-Up Zone Duration": "W-U Z Dur (m s)",
    "Warm-Up Zone Distance (%)": "W-U Z Dist (%)",
    "Warm-Up Zone Duration (%)": "W-U Z Dur (%)",
    "Fitness Zone Distance": "Fit Z Dist (m)",
    "Fitness Zone Duration": "Fit Z Dur (m s)",
    "Fitness Zone Distance (%)": "Fit Z Dist (%)",
    "Fitness Zone Duration (%)": "Fit Z Dur (%)",
    "Performance Zone Distance": "Perf Z Dist (m)",
    "Performance Zone Duration": "Perf Z Dur (m s)",
    "Performance Zone Distance (%)": "Perf Z Dist (%)",
    "Performance Zone Duration (%)": "Perf Z Dur (%)",
    "High Intensity Zone Distance": "Int Z Dist (m)",
    "High Intensity Zone Duration": "Int Z Dur (m s)",
    "High Intensity Zone Distance (%)": "Int Z Dist (%)",
    "High Intensity Zone Duration (%)": "Int Z Dur (%)",
}


const createColNameHelper = (n, zName) => {
    const COLUMN_NAME_CONVERTER = {
        "distance": `${ZONE_CONVERTER[zName]} Distance`,
        "distance_percentage": `${ZONE_CONVERTER[zName]} Distance (%)`,
        "duration": `${ZONE_CONVERTER[zName]} Duration`,
        "duration_percentage": `${ZONE_CONVERTER[zName]} Duration (%)`,
    };

    return COLUMN_NAME_CONVERTER[n];
}

const colHelper = (data, zoneName) => {
    const colNames = Object.keys(data[zoneName])
        .filter(key => !EXCLUDED_ZONE_COLUMNS.includes(key))

    return colNames.map(n => createColNameHelper(n, zoneName));
}

const convertZoneColumnHelper = (player, col) => {
    const [zone, metricName] = splitString(col);

    // Checks if player has Zone Values
    return player["zone1"]
        ? player[ZONE_CONVERTER[zone]][COLUMN_NAME_CONVERTER[metricName]]
        : { display: "-", value: 0 };
}

// Function to handle state and return different position groups
const getPositionGroupsBySport = (sport) => {
  switch (sport) {
    case "Football":
      return {
        area: ["Attacker", "Midfielder", "Defender"],
        groups: FOOTBALL_POSITIONS,
      };

    case "football":
      return {
        area: ["Attacker", "Midfielder", "Defender"],
        groups: FOOTBALL_POSITIONS,
      };

    case "Basketball":
      return {
        area: ["Guard", "Forward", "Center"],
        groups: BASKETBALL_POSITIONS,
      };

    default:
      return {
        area: [],
        groups: {},
      };
  }
};

// Function to fiilter out the excluded keys and create the filtered columns from Zones
const filterColumns = (data, keys, sport) => {
    const basicMetrics = keys.filter((key) => !EXCLUDED_KEYS.includes(key));

    let filteredColumns = [basicMetrics];

    // Checks if item has Zone Values
    if (keys.includes("zone1")) {
        const i = data.findIndex(item => item?.["zone1"]);

        const moreColumns = sport === "Basketball"
            ? [colHelper(data[i], "zone1"), colHelper(data[i], "zone2"), colHelper(data[i], "zone3")]
            : [colHelper(data[i], "zone1"), colHelper(data[i], "zone2"), colHelper(data[i], "zone3"), colHelper(data[i], "zone4")];

        filteredColumns = [...filteredColumns, ...moreColumns];
    }

    return filteredColumns;
}

// Function to fiilter the selected columns and format the names for a player
const filterPlayerColumns = (player, positionGroup, selectedColumns) => {
    let positionArea;
    for (const [key, positions] of Object.entries(positionGroup)) {
        if (positions.includes(player.position)) {
            positionArea = key;
            break;
        }
    }

    const filteredPlayer = {
        Player: `${player.firstname[0]}. ${player.lastname}`,
        Position: player.position,
        positionArea: positionArea,
    };

    selectedColumns.forEach((col) => {
        filteredPlayer[PDF_NAME_VERSION_CONVERTER[col]] =
            player[col] ?
                player[col] :
                convertZoneColumnHelper(player, col);
    });

    return filteredPlayer;
}

// Function to fiilter the selected columns for the team 
const filterTeamData = (teamData, selectedColumns) => {
    const filteredTeamData = {};
    Object.keys(teamData).forEach(key => {
        filteredTeamData[key] = {};

        selectedColumns.forEach((col) => {
            const formatedColName = FETCH_VALUES_TO_VAR_DECONVERTER[col] ?? col;
            const pdfColName = PDF_NAME_VERSION_CONVERTER[col];
            let value;

            if (containWord(pdfColName, "(%)")) {
                value = `${teamData[key][formatedColName]}%`;
            } else if (containWord(pdfColName, "(m s)")) {
                value = formatTime(teamData[key][formatedColName]);
            } else {
                value = teamData[key][formatedColName];
            }

            filteredTeamData[key][PDF_NAME_VERSION_CONVERTER[col]] = value;
        })
    });

    return filteredTeamData;
}

export { getPositionGroupsBySport, filterPlayerColumns, filterColumns, filterTeamData };
