// Converts speed between m/s and km/h. Output is rounded to 2 digits after decimal comma
const convertSpeed = (value, toKmH = true) => {
    if (typeof value !== 'number' || isNaN(value)) {
        return value;
    }
    const result = toKmH ? value * 3.6 : value / 3.6;
    return Math.round(result * 100) / 100;
};

const formatTime = (value) => {
    if (isNaN(value) || !value)
        return "0m 0s";

    const minutes = Math.floor(value / 60).toString().padStart(2, "0");
    const seconds = Math.round(value % 60).toString().padStart(2, "0");
    return `${minutes}m ${seconds}s`;
};

const calculatePercentage = (val, totalVal) => {
    return totalVal > 0 ? ((val / totalVal) * 100).toFixed(2) : "0.00";
};

const splitString = (inputString, str = "Zone", afterStr = true) => {
    const regex = new RegExp(`(${str}.*?)`, 'i');
    const match = inputString.match(regex);

    if (!match) {
        return [inputString];
    }

    const matchEnd = match.index + match[0].length;

    // Decide splitting based on `afterStr`
    if (afterStr) {
        const beforeStr = inputString.substring(0, matchEnd).trim(); // Include `str` in the before part
        const afterStr = inputString.substring(matchEnd).trim();    // Text after the matched part
        return [beforeStr, afterStr];
    } else {
        const beforeStr = inputString.substring(0, match.index).trim(); // Text before the matched part
        const afterStr = inputString.substring(match.index).trim();     // Include `str` in the after part
        return [beforeStr, afterStr];
    }
};

// Converts variable to words shown in ui (ex: activeDistane to Active Distance)
const convertVarToColName = (col) => {
    if (col === "accel") {
        return "Acceleration";
    } else if (col === "decel") {
        return "Deceleration";
    } else if (col === "engagementTime") {
        return "Total Time";
    } 

    if (col[0] === col[0].toUpperCase())
        return col

    // Split the string whenever there's a capital letter
    const parts = col.split(/(?=[A-Z])/);

    // Capitalize the first letter of each part and make the rest lowercase
    const formattedParts = parts.map(part =>
        part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
    );

    // Join the formatted parts with a space
    return formattedParts.join(' ');
}

// Checks if a specific word is in the string, either as a whole word or part of it.
const containWord = (str, word, checkWholeWord = false) => {
    if (typeof str !== "string" || typeof word !== "string") {
        return false;
    }

    // Create the regular expression based on the user's preference
    const regex = checkWholeWord ? new RegExp(`\\b${word}\\b`, "i") : new RegExp(word, "i");
    return regex.test(str);
}


export {
    convertSpeed,
    formatTime,
    calculatePercentage,
    splitString,
    convertVarToColName,
    containWord
};
